import React, { useState, useEffect, useContext } from "react";
import Announcment from "../components/Announcment";
import Categories from "../components/Categories";
import Footer from "../layouts/footer/Footer";
import HowToVelo from "../components/HowToVelo";
import Recommended from "../components/Recommended";
import MainNavbar from "../layouts/header/MainNavbar";
import styled from "styled-components";
import ProductCardSlider from "../components/ProductCardSlider";
import HomeSlider from "../components/HomeSlider";
import APIService from "../services/APIService";
import { CallMade, WhatsApp } from "@mui/icons-material";
import { useNavigation } from "../utils/navigation";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { ProductContext } from "../context/ProductContext";
import NotifyPopUp from "../components/NotifyPopUp";
import CategoriesAnim from "../components/CategoriesAnim";
import { useTranslation } from "react-i18next";

const RecommendedContainerDesktop = styled.div`
  display: block;
  max-width: 100vw;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const RecommendedContainerPhone = styled.div`
  max-width: 100vw;
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`;
const RecommendedTitleContainer = styled.div`
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2px 0px;
  background-color: ${(props) => props.background_color};
  @media only screen and (max-width: 768px) {
    padding: 30px 5px;
    flex-direction: column;
  }
`;
const RecommendedTitle = styled.h1`
  letter-spacing: 0;
  font-family: "Righteous";
  font-weight: 100;
  text-transform: uppercase;
  color: white;
  font-size: 35px;
  padding: 0;
  margin: 0;
  //line-height: 1.2;
  @media only screen and (max-width: 768px) {
    text-align: center;
    font-size: 25px;
  }
`;

const HomeSliderWrapper = styled.div``;

const ViewAllButton = styled.button`
  line-height: 1.25;
  letter-spacing: 0;
  font-weight: 700;
  color: white;
  display: block;
  text-transform: uppercase;
  font-size: 16px;
  text-align: left;
  margin: 0;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;
const Icon = styled.div`
  transition: transform 0.3s ease-in-out;
  transform: ${({ rotated }) => (rotated ? "rotate(45deg)" : "rotate(0)")};
`;
const Home = () => {
  const { t } = useTranslation();
  const [lowIntensityArticles, setLowIntensityArticles] = useState([]);
  const [mediumIntensityArticles, setMediumIntensityArticles] = useState([]);
  const [highIntensityArticles, setHighIntensityArticles] = useState([]);
  const { recommendedArticles, popUp } = useContext(ProductContext);
  const [rotated, setRotated] = useState(false);
  const { goToShop } = useNavigation();
  useEffect(() => {
    APIService.GetArticlesByNicotineIntensity("1,29").then((resp) => {
      console.log(resp);
      setLowIntensityArticles(resp.slice(0, 6));
    });
    APIService.GetArticlesByNicotineIntensity("30,51").then((resp) => {
      setMediumIntensityArticles(resp.slice(0, 6));
    });
    APIService.GetArticlesByNicotineIntensity("52,1000").then((resp) => {
      setHighIntensityArticles(resp.slice(0, 6));
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>KUCA | Snus Srbija</title>
        <meta property="og:title" content="KUCA | Snus Srbija" />
        <meta
          name="description"
          content="Obavestavamo Vas da od danas mozete snus/eve kupiti i u Srbiji pomocu nase online platforme. Ne propustite odlicnu proliku koju Vam nudimo."
        />
        <meta
          property="og:description"
          content="Snus možete kupiti online u Srbiji. Pronađite najbolje proizvode i pušački nikotinski proizvod na našem websajtu."
        />
        <meta
          property="og:image"
          content={require("../assets/images/homeCover.webp")}
        />
        <link rel="canonical" href="https://www.snussrbija.com/" />
        <meta
          name="keywords"
          content="Snus Srbija, snus online kupovina, snus Beograd, snus Novi Sad, snus Subotica"
        />
      </Helmet>
      {popUp && <NotifyPopUp />}
      <MainNavbar />
      <Announcment />
      <HomeSliderWrapper>
        <HomeSlider />
      </HomeSliderWrapper>
      {/* <CategoriesAnim/> */}
      <Categories />

      <RecommendedContainerPhone>
        <RecommendedTitleContainer background_color="teal">
          <ViewAllButton
            onMouseEnter={() => setRotated(true)}
            onMouseOut={() => setRotated(false)}
            onClick={() => goToShop()}
          >
            {t("Home.SHOP")}{" "}
            <p style={{ color: "#f4d35e", margin: "0 5px" }}>
              {t("Home.SEE_ALL")}
            </p>
            <Icon rotated={rotated}>
              <CallMade style={{ color: "#f4d35e" }} />
            </Icon>
          </ViewAllButton>
        </RecommendedTitleContainer>
        <RecommendedTitleContainer background_color="#008080">
          <RecommendedTitle>
            {t("Home.LOW_NICOTINE_INTENSITY")}
          </RecommendedTitle>
        </RecommendedTitleContainer>
        <ProductCardSlider sliderContent={lowIntensityArticles} />
        <RecommendedTitleContainer background_color="#00AEAE">
          <RecommendedTitle>
            {t("Home.MEDIUM_NICOTINE_INTENSITY")}
          </RecommendedTitle>
        </RecommendedTitleContainer>
        <ProductCardSlider sliderContent={mediumIntensityArticles} />

        <RecommendedTitleContainer background_color="#008080">
          <RecommendedTitle>
            {t("Home.HIGH_NICOTINE_INTENSITY")}
          </RecommendedTitle>
        </RecommendedTitleContainer>
        <ProductCardSlider sliderContent={highIntensityArticles} />
      </RecommendedContainerPhone>
      <RecommendedContainerDesktop>
        <RecommendedTitleContainer background_color="teal">
          <RecommendedTitle>{t("Home.SEE_ALL")}</RecommendedTitle>
          <ViewAllButton
            onMouseEnter={() => setRotated(true)}
            onMouseOut={() => setRotated(false)}
            onClick={() => goToShop()}
          >
            {t("Home.SHOP")}{" "}
            <p style={{ color: "#f4d35e", margin: "0 5px" }}>
              {t("Home.SEE_ALL")}
            </p>
            <Icon rotated={rotated}>
              <CallMade style={{ color: "#f4d35e" }} />
            </Icon>
          </ViewAllButton>
        </RecommendedTitleContainer>
        {/* <Recommended articles={recommendedArticles} />  */}
        <div style={{ padding: "0px 200px" }}>
          <ProductCardSlider sliderContent={recommendedArticles} />
        </div>
      </RecommendedContainerDesktop>
      {/* <div style={{margin:"2px 0px"}}>
            <HowToVelo />
        </div> */}
      <div
        style={{
          position: "sticky",
          bottom: "40px",
          zIndex: "99",
          height: "0px",
          cursor: "pointer",
        }}
      >
        <Link
          to="https://wa.me/message/LTLCI6ROYGLQD1"
          aria-label="Posetite našu WhatsApp aplikaciju"
        >
          <WhatsApp
            style={{ color: "#34B7F1", fontSize: "35px", marginLeft: "10px" }}
          />
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
